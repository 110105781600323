import React, { useRef, useState } from 'react';

function App() {
  const audioRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const fetchJokeAndStreamAudio = () => {
    setLoading(true);
    const audioUrl =
      'https://tellmeajoke-8b4f705d0fba.herokuapp.com/random-joke-stream';

    if (audioRef.current) {
      audioRef.current.src = audioUrl;
      audioRef.current
        .play()
        .then(() => setLoading(false))
        .catch((e) => {
          console.log('Error playing:', e);
          setLoading(false);
        });
    }
  };

  const fetchHeadlineAndStreamAudio = () => {
    setLoading(true);
    const audioUrl =
      'https://tellmeajoke-8b4f705d0fba.herokuapp.com/random-headline-stream';

    if (audioRef.current) {
      audioRef.current.src = audioUrl;
      audioRef.current
        .play()
        .then(() => setLoading(false))
        .catch((e) => {
          console.log('Error playing:', e);
          setLoading(false);
        });
    }
  };

  return (
    <div className="App">
      <h1>Listen to a Streaming Joke</h1>
      <button onClick={fetchJokeAndStreamAudio} disabled={loading}>
        {loading ? 'Loading...' : 'Tell me an engineering joke'}
      </button>
      <button onClick={fetchHeadlineAndStreamAudio} disabled={loading}>
        {loading ? 'Loading...' : 'Tell me a topical joke'}
      </button>
      <audio ref={audioRef} controls />
    </div>
  );
}

export default App;
